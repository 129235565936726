// import { useGtm } from "@gtm-support/vue-gtm";
// import { useGtm } from '@zadigetvoltaire/nuxt-gtm';
const gtm = useGtm();

// export const itemsExample = [
//   {
//     item_id: "SKU_12345",
//     item_name: "Stan and Friends Tee",
//     affiliation: "Google Merchandise Store",
//     coupon: "SUMMER_FUN",
//     discount: 2.22,
//     index: 0,
//     item_brand: "Google",
//     item_category: "Apparel",
//     item_category2: "Adult",
//     item_category3: "Shirts",
//     item_category4: "Crew",
//     item_category5: "Short sleeve",
//     item_list_id: "related_products",
//     item_list_name: "Related Products",
//     item_variant: "green",
//     location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
//     price: 9.99,
//     quantity: 1,
//   },
//   {
//     item_id: "SKU_12346",
//     item_name: "Google Grey Women's Tee",
//     affiliation: "Google Merchandise Store",
//     coupon: "SUMMER_FUN",
//     discount: 3.33,
//     index: 1,
//     item_brand: "Google",
//     item_category: "Apparel",
//     item_category2: "Adult",
//     item_category3: "Shirts",
//     item_category4: "Crew",
//     item_category5: "Short sleeve",
//     item_list_id: "related_products",
//     item_list_name: "Related Products",
//     item_variant: "gray",
//     location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
//     price: 20.99,
//     quantity: 1,
//   },
// ];
/// THIS IS THE DOCUMENTATION LINK
/// https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm

// Clear the previous ecommerce object.
const clearDataLayer = () => {
  // console.log("GTM clearDataLayer");
  window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
};

// export const viewItemList = () => {
//   clearDataLayer();
//   window.dataLayer.push({
//     event: "view_item_list",
//     ecommerce: {
//       item_list_id: "related_products",
//       item_list_name: "Related products",
//       items: [
//         {
//           item_id: "SKU_12345",
//           item_name: "Stan and Friends Tee",
//           affiliation: "Google Merchandise Store",
//           coupon: "SUMMER_FUN",
//           discount: 2.22,
//           index: 0,
//           item_brand: "Google",
//           item_category: "Apparel",
//           item_category2: "Adult",
//           item_category3: "Shirts",
//           item_category4: "Crew",
//           item_category5: "Short sleeve",
//           item_list_id: "related_products",
//           item_list_name: "Related Products",
//           item_variant: "green",
//           location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
//           price: 9.99,
//           quantity: 1,
//         },
//       ],
//     },
//   });
// };

// export const selectItem = () => {
//   clearDataLayer();
//   window.dataLayer.push({
//     event: "select_item",
//     ecommerce: {
//       item_list_id: "related_products",
//       item_list_name: "Related products",
//       items: [
//         {
//           item_id: "SKU_12345",
//           item_name: "Stan and Friends Tee",
//           affiliation: "Google Merchandise Store",
//           coupon: "SUMMER_FUN",
//           discount: 2.22,
//           index: 0,
//           item_brand: "Google",
//           item_category: "Apparel",
//           item_category2: "Adult",
//           item_category3: "Shirts",
//           item_category4: "Crew",
//           item_category5: "Short sleeve",
//           item_list_id: "related_products",
//           item_list_name: "Related Products",
//           item_variant: "green",
//           location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
//           price: 9.99,
//           quantity: 1,
//         },
//       ],
//     },
//   });
// };

export const trackViewItem = ({ items, value = 0, customer_id }) => {
  clearDataLayer();

  // console.log("GTM viewItem", { items, value, customer_id });

  window.dataLayer.push({
    event: "view_item",
    ecommerce: {
      currency: "EUR",
      value: +value,
      customer_id,
      items,
    },
  });
};

export const trackAddToCart = ({ items, value, customer_id }) => {
  clearDataLayer();
  // console.log("GTM addToCart", { items, value });
  window.dataLayer.push({
    event: "add_to_cart",
    ecommerce: {
      currency: "EUR",
      value: +value,
      customer_id,
      items,
    },
  });
};

export const trackRemoveFromCart = ({ items, value, customer_id }) => {
  clearDataLayer();
  // console.log("GTM removeFromCart", { items, value });
  window.dataLayer.push({
    event: "remove_from_cart",
    ecommerce: {
      currency: "EUR",
      value: +value,
      customer_id,
      items: items,
    },
  });
};

export const trackUpdateQtyinCart = ({ items, value, customer_id }) => {
  clearDataLayer();
  // console.log("GTM updateQtyinCart", { items, value, customer_id });
  window.dataLayer.push({
    event: "update_cart",
    ecommerce: {
      currency: "EUR",
      value: +value,
      customer_id,
      items,
    },
  });
};
export const trackViewCart = ({ items, value, customer_id }) => {
  clearDataLayer();
  // console.log("GTM viewCart", { items, value, customer_id });
  window.dataLayer.push({
    event: "view_cart",
    ecommerce: {
      currency: "EUR",
      value: +value,
      customer_id,
      items,
    },
  });
};

//#region  Initiate the checkout process
export const trackBeginCheckout = ({ items, value, customer_id }) => {
  clearDataLayer();
  // console.log("GTM beginCheckout", { items, value });
  window.dataLayer.push({
    event: "begin_checkout",
    ecommerce: {
      currency: "EUR",
      value: +value,
      customer_id,
      items: items,
    },
  });
};
export const trackPurchase = ({
  id,
  items,
  value,
  tax,
  shipping,
  customer_id,
}) => {
  clearDataLayer();
  // console.log("GTM purchase", { id, items, value, tax, shipping, customer_id });
  window.dataLayer.push({
    event: "purchase",
    ecommerce: {
      transaction_id: id,
      value: +value,
      tax: +tax,
      shipping: +shipping,
      currency: "EUR",
      customer_id,
      items: items,
    },
  });
};
// export const addShippingInfo = () => {
//   clearDataLayer();
//   window.dataLayer.push({
//     event: "add_shipping_info",
//     ecommerce: {
//       currency: "EUR",
//       value: 7.77,
//       coupon: "SUMMER_FUN",
//       shipping_tier: "Ground",
//       items: [
//         {
//           item_id: "SKU_12345",
//           item_name: "Stan and Friends Tee",
//           affiliation: "Google Merchandise Store",
//           coupon: "SUMMER_FUN",
//           discount: 2.22,
//           index: 0,
//           item_brand: "Google",
//           item_category: "Apparel",
//           item_category2: "Adult",
//           item_category3: "Shirts",
//           item_category4: "Crew",
//           item_category5: "Short sleeve",
//           item_list_id: "related_products",
//           item_list_name: "Related Products",
//           item_variant: "green",
//           location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
//           price: 9.99,
//           quantity: 1,
//         },
//       ],
//     },
//   });
// };

// export const addPaymentInfo = () => {
//   clearDataLayer();
//   window.dataLayer.push({
//     event: "add_payment_info",
//     ecommerce: {
//       currency: "EUR",
//       value: 7.77,
//       coupon: "SUMMER_FUN",
//       payment_type: "Credit Card",
//       items: [
//         {
//           item_id: "SKU_12345",
//           item_name: "Stan and Friends Tee",
//           affiliation: "Google Merchandise Store",
//           coupon: "SUMMER_FUN",
//           discount: 2.22,
//           index: 0,
//           item_brand: "Google",
//           item_category: "Apparel",
//           item_category2: "Adult",
//           item_category3: "Shirts",
//           item_category4: "Crew",
//           item_category5: "Short sleeve",
//           item_list_id: "related_products",
//           item_list_name: "Related Products",
//           item_variant: "green",
//           location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
//           price: 9.99,
//           quantity: 1,
//         },
//       ],
//     },
//   });
// };

// export const refund = () => {
//   clearDataLayer();
//   window.dataLayer.push({
//     event: "refund",
//     ecommerce: {
//       currency: "EUR",
//       transaction_id: "T_12345", // Transaction ID. Required for purchases and refunds.
//       value: 12.21,
//       coupon: "SUMMER_FUN",
//       shipping: 3.33,
//       tax: 1.11,
//       items: [
//         {
//           item_id: "SKU_12345",
//           item_name: "Stan and Friends Tee",
//           affiliation: "Google Merchandise Store",
//           coupon: "SUMMER_FUN",
//           discount: 2.22,
//           index: 0,
//           item_brand: "Google",
//           item_category: "Apparel",
//           item_category2: "Adult",
//           item_category3: "Shirts",
//           item_category4: "Crew",
//           item_category5: "Short sleeve",
//           item_list_id: "related_products",
//           item_list_name: "Related Products",
//           item_variant: "green",
//           location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
//           price: 9.99,
//           quantity: 1,
//         },
//       ],
//     },
//   });
// };
//#endregion

// export const viewPromotion = () => {
//   clearDataLayer();
//   window.dataLayer.push({
//     event: "view_promotion",
//     ecommerce: {
//       creative_name: "Summer Banner",
//       creative_slot: "featured_app_1",
//       promotion_id: "P_12345",
//       promotion_name: "Summer Sale",
//       items: [
//         {
//           item_id: "SKU_12345",
//           item_name: "Stan and Friends Tee",
//           affiliation: "Google Merchandise Store",
//           coupon: "SUMMER_FUN",
//           discount: 2.22,
//           index: 0,
//           item_brand: "Google",
//           item_category: "Apparel",
//           item_category2: "Adult",
//           item_category3: "Shirts",
//           item_category4: "Crew",
//           item_category5: "Short sleeve",
//           item_list_id: "related_products",
//           item_list_name: "Related Products",
//           item_variant: "green",
//           location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
//           price: 9.99,
//           quantity: 1,
//         },
//       ],
//     },
//   });
// };

// export const selectPromotion = () => {
//   clearDataLayer();
//   window.dataLayer.push({
//     event: "select_promotion",
//     ecommerce: {
//       creative_name: "Summer Banner",
//       creative_slot: "featured_app_1",
//       promotion_id: "P_12345",
//       promotion_name: "Summer Sale",
//       items: [
//         {
//           item_id: "SKU_12345",
//           item_name: "Stan and Friends Tee",
//           affiliation: "Google Merchandise Store",
//           coupon: "SUMMER_FUN",
//           discount: 2.22,
//           index: 0,
//           item_brand: "Google",
//           item_category: "Apparel",
//           item_category2: "Adult",
//           item_category3: "Shirts",
//           item_category4: "Crew",
//           item_category5: "Short sleeve",
//           item_list_id: "related_products",
//           item_list_name: "Related Products",
//           item_variant: "green",
//           location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
//           price: 9.99,
//           quantity: 1,
//         },
//       ],
//     },
//   });
// };

export const mapProduct = (products) => {
  let items = [];

  for (let index = 0; index < products.length; index++) {
    const product = products[index];
    const brand = product.brandText;
    const name =
      typeof product.name === "string" ? product.name : product.name["en_GB"];

    /* 
    
    delete product["name"];
    delete product["brand_text"];
    delete product["price"];
    delete product["images"];
    delete product["description"];
    delete product["vg_menu_languages"];
    delete product["productImageLarge"];
    
    */
    const item = {
      item_id: product.Identifier,
      item_name: name,
      item_brand: brand,
      price: product.dvdSuggestedPriceAmount,
      index,
    };

    if (product.platforms.length) {
      for (let j = 0; j < product.platforms.length; j++) {
        const platform = product.platforms[j];
        item[`item_category${j + 1}`] = platform;
      }
    }

    items.push(item);
  }
  return items;
};
export const mapViewProduct = (products) => {
  let items = [];

  for (let index = 0; index < products.length; index++) {
    const product = products[index];
    const brand = product.brand_text.en_GB || product.brand_text.fr_BE;
    const name = product.name.en_GB || product.name.fr_BE;

    const item = {
      item_id: product.identifier,
      item_name: name,
      item_brand: brand,
      price: product.dvdSuggestedPriceAmount,
      index,
    };

    if (product.platforms.length) {
      for (let j = 0; j < product.platforms.length; j++) {
        const platform = product.platforms[j];
        item[`item_category${j + 1}`] = platform;
      }
    }

    items.push(item);
  }
  return items;
};

// export const mapProductSearchItem = (product) => {
//   debugger
//   const item = {
//     item_id: product.S_ID,
//     item_name: product.name,
//     item_brand: product.D_NOM,
//     price: product.RetailPrice,
//     index: 0,
//   };

//   if (product.PLATEFORMS.length) {
//     for (let j = 0; j < product.PLATEFORMS.length; j++) {
//       const platform = product.PLATEFORMS[j];
//       item[`item_category${j + 1}`] = platform;
//     }
//   }
//   return [item];
// };

export const mapProductCartItems = (products) => {
  let items = [];
  for (let index = 0; index < products.length; index++) {
    
    const product = products[index];
    const item = {
      item_id: product.articleId,
      item_name: product.name,
      // item_brand: brand,
      price: product.total,
      index,
      quantity: product.quantity,
    };

    // if (product.platforms.length) {
    //   for (let j = 0; j < product.platforms.length; j++) {
    //     const platform = product.platforms[j];
    //     item[`item_category${j + 1}`] = platform;
    //   }
    // }

    items.push(item);
  }
  return items;
};
